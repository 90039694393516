<template>
  <BCard
    header-tag="h2"
    header-bg-variant="ams-gray"
    header-border-variant="ams-header-orange"
    header-text-variant="black"
    header-class="border-bottom highlight-border"
    align="left"
    class="m3 shadow mt-3"
  >
    <template slot="header">
      Recent {{ recentType }}
    </template>
    <BRow
      v-if="unassigned != undefined && unassigned != null"
      class="volIssRow"
    >
      <BCol>
        <a
          v-if="unassigned.url !== undefined"
          :href="unassigned.url"
        >
          {{ unassigned.text }}
        </a>
        <RouterLink
          v-if="unassigned.to !== undefined"
          :to="unassigned.to"
        >
          {{ unassigned.text }}
        </RouterLink>
      </BCol>
    </BRow>
    <SerialVolIss
      :vol-iss-formatted="volIssFormatted"
      :internal="internal"
      :small="true"
      :data-type="recentType"
    />
    <BButton
      v-if="recentLink && recentLink.url"
      variant="ams-blue-dark"
      :href="recentLink.url"
      class="indent-xs mt-3"
    >
      {{ recentLink.text }}
    </BButton>
    <BButton
      v-if="recentLink && recentLink.to"
      variant="ams-blue-dark"
      :to="recentLink.to"
      class="indent-xs mt-3"
    >
      {{ recentLink.text }}
    </BButton>
  </BCard>
</template>
<script>
export default {
  name: 'SerialVolIssCard',
  props: {
    recentType: {
      type: String,
      default: '',
    },
    recentLink: {
      type: Object,
      required: false,
      default: null,
    },
    volIssFormatted: {
      type: Array,
      required: true,
    },
    unassigned: {
      type: Object,
      required: false,
      default: null,
    },
    internal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SerialVolIss: () => import(/* webpackChunkName: "journal_serialvoliss" */ '@/components/journal/SerialVolIss.vue'),
  },
}
</script>
